<template>
  <div class="collapse show" v-if="show">
    <div class="filter-content">
      <form @submit.prevent="applyFilters">
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="ordernumber" class="col-form-label">{{ activeLanguage.ordernumber }}</label>
                <input type="text" v-model="form.order_number" id="ordernumber" class="form-control">
              </div>
              <div class="form-group col-md-4">
                <label for="emailaddress" class="col-form-label">{{ activeLanguage.email_address }}</label>
                <input type="text" v-model="form.email" id="emailaddress" class="form-control">
              </div>

              <div class="form-group col-md-4">
                <label for="status" class="col-form-label">Status</label>
                <b-form-select v-model="form.status" :options="statuses">
                  <template slot="first">
                    <option :value="undefined" disabled>Select status</option>
                  </template>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="closing_reason" class="col-form-label">{{ activeLanguage.closing_reason }}</label>
            <b-form-select v-model="form.closing_reason" :options="closing_reasons">
              <template slot="first">
                <option :value="undefined" disabled>Select closing reason</option>
              </template>
            </b-form-select>
          </div>
          <div class="form-group col-md-8">
            <label for="date" class="col-form-label">{{ activeLanguage.date }}</label>
            <div class="event_period">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="dateFrom" class="sr-only">{{ activeLanguage.date }}</label>
                  <div class="input-group">
                    <Datepicker
                        v-model="form.created_from"
                        :calendar-button="true"
                        calendar-button-icon="fa fa-calendar"
                        input-class="form-control actual_range"
                        format="yyyy-MM-dd"
                        :placeholder="activeLanguage.from"
                        :clear-button="true"
                        @selected="onDateChange($event, 'dateFrom')"
                    />
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="dateTo" class="sr-only"></label>
                  <div class="input-group">
                    <Datepicker
                        v-model="form.created_to"
                        :calendar-button="true"
                        calendar-button-icon="fa fa-calendar"
                        input-class="form-control actual_range"
                        format="yyyy-MM-dd"
                        :placeholder="activeLanguage.to"
                        :clear-button="true"
                        @selected="onDateChange($event, 'dateTo')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <a class="btn btn-secondary" style="margin-right: 5px;" @click.prevent="clearFilters">{{ activeLanguage.clear }}</a>
          <button type="submit" class="btn" :disabled="!(formChanged && !clearFired)">{{ activeLanguage.apply }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  name: "CollectionFilter",
  props:{
    activeLanguage:{
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {},
      formChanged: false,
      clearFired: false,
      show: true,
      statuses:['Closures', 'Extrajudicial', 'Arrangements', 'Minnelijk traject', 'Requests', 'Dossier gesloten',
                'Curatele/administration', 'Betalingsregeling status', 'Bankruptcy', 'In behandeling', 'Bewind'],
      closing_reasons: ['', 'Volledig betaald', 'Oninbaar', 'Geen reactie', 'Compliance Definitief', 'Terug naar Billink',
              'Ingetrokken door Cliënt', 'Voldaan bij Opdrachtgever/Webshop', 'Bewindvoering', 'Geen reactie van Cliënt',
              'Hoofdsom (deels) betaald bij Opdrachtgever/Webshop', 'Schikking', 'Geen verhaal mogelijk', 'Gedeeltelijk Betaald',
              'Betaald voor overdracht', 'Fraude', 'Voldaan bij deurwaarder', 'Samengevoegd', 'Detentie', 'Schuldhulpverlening',
              'Betwist', 'Compliance in Afwachting', 'Wachten op reactie Debiteur', 'WSNP', 'Wachten op reactie curator schuldhulpverlener',
              'Overleden', 'Wachten op reactie Client', 'Faillissement', 'Einde pre-casso', 'Restant voldaan bij deurwaarder',
              'Wachten op terugkoppeling', 'Vertrokken onbekend waarheen', 'Hoofdsom ontvangen', 'Hoofdsom (deels) betaald bij Opdrachtgever Webshop',
              'Voldaan bij Opdrachtgever Webshop', 'Wachten op reactie derde', 'Einde minnelijk traject', 'Laatste kans',
              'Herinnering 1', 'Herinnering 2', 'Herinnering 3', 'Herinnering betalingsregeling',
              'Betalingsregeling', 'Verstuur E-mail', 'Verstuur SMS', 'Geemigreerd', 'Wachten op reactie curator/schuldhulpverlener',
              'Poging tot telefonisch contact 1', 'Poging tot telefonisch contact 2', 'Poging tot telefonisch contact 3',
              'Geeft aan betaald te hebben', 'Niet te traceren in BRP']
    }
  },
  mounted() {
    this.handleStatusesLang()
  },
  methods: {
    handleStatusesLang(){
      switch (this.activeLanguage.lang) {
        case 'nl' :
          this.statuses = ['Gesloten', 'Buitengerechtelijk', 'Regeling', 'Minnelijk traject', 'Verzoek', 'Dossier gesloten',
            'Curatele/administration', 'Betalingsregeling status', 'Faillissement', 'In behandeling', 'Bewind']
              break;
          case 'en' :
            this.statuses = ['Closures', 'Extrajudicial', 'Arrangements', 'Minnelijk traject', 'Requests', 'Dossier gesloten',
              'Curatele/administration', 'Betalingsregeling status', 'Bankruptcy', 'In behandeling', 'Bewind']
              break;
      }
    },
    applyFilters(){
      this.$emit('onFiltersApply', this.form)
    },
    onDateChange(date, type) {
      this.formChanged = true;
      date ? this.form[type] = date : delete this.form[type]
    },
    clearFilters(){
      this.form = {}
      this.clearFired = true
      this.$emit('onClearFilters')
    },
    toggleFilter() {
      this.show = !this.show
    }

  },
  components: {
    Datepicker
  },
  watch: {
    form: function (newVal, oldVal) {
      this.formChanged = true;

      //allow to handle enable/disable for "Apply filter" btn
      if (newVal === oldVal) {this.clearFired = false}
    },
    activeLanguage: function (newVal, oldVal) {
      if (newVal !== oldVal) {this.handleStatusesLang()}
    }
  }
}
</script>