<template>
  <div class="main">
    <div class="container">
      <div class="title">
        <h1>
          {{ activeLanguage.title }}
        </h1>
      </div>

      <div class="filter-bar-container">
        <div class="filter-bar">
          <div>
            <button type="button" class="btn btn-secondary" @click="toggleFilter">
              <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
              Filter
              <i class="fa fa-caret-down caret" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <transition name="collapse">
          <CollectionFilter
              @onFiltersApply="onFiltersApply"
              @onClearFilters="onClearFilters"
              :activeLanguage="activeLanguage.filter"
              ref="collectionFilter"
          />
        </transition>
      </div>

      <table class="table">
        <thead>
        <tr>
          <th>
            {{ activeLanguage.ordernumber }}
          </th>
          <th>
            {{ activeLanguage.debitor_name }}
          </th>
          <th>
            {{ activeLanguage.order_amount }}
          </th>
          <th>
            Status
          </th>
          <th>
            {{ activeLanguage.total_paid_amount }}
          </th>
          <th>
            {{ activeLanguage.closing_reason }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="order in data" @click="onOrderClick(order.order_number)">
          <td>
            {{ order.order_number }}
          </td>
          <td>
            {{ order.debtor_firstname + ' ' + order.debtor_lastname }}
          </td>
          <td>
            {{ formattedAmount(parseFloat(order.order_amount)) }}
          </td>
          <td>
            {{ order.status }}
          </td>
          <td>
            {{ (parseFloat(order.total_paid_amount).toFixed(2) >= parseFloat(order.order_amount).toFixed(2)) ? activeLanguage.yes : activeLanguage.no }}
          </td>
          <td>
            {{ order.closing_reason }}
          </td>
        </tr>
        </tbody>
      </table>

      <div class="col-12">
        <div style="height: 35px;"></div>
        <b-pagination
            align="center"
            :prev-text="paginationLanguage.pagPrev"
            :next-text="paginationLanguage.pagNext"
            :limit="6"
            @input="getCibStatuses(pagData.currentPage)"
            :total-rows="pagData.maxItems"
            v-model="pagData.currentPage"
            :per-page="10"
            v-if="pagData.maxItems > 10"
            :disabled="false"
        />
        <PaginationCount
            :pagData="pagData"
            :selection="csvIds"
            :activeLanguage="paginationLanguage.pagTxt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CollectionFilter from './CollectionFilter'
import Pagination from '@/components/common/reusable/Pagination.vue'
import PaginationCount from '@/components/common/reusable/PaginationCount.vue'

  export default {
    name: "Collection",
    data () {
      return {
        data: [],
        activeFilters: {},
        pagData: {
          currentPage: 1,
          maxItems: 0
        },
        csvIds: []
      }
    },
    mounted() {
      this.getCibStatuses()
    },
    methods: {
      getCibStatuses(page = 1, perPage = 20) {
        let params = {
          filter: this.activeFilters,
          page,
          perPage
        }

        billinkAxios
            .get(`app/cib-order-statuses`, {params})
            .then(({ data }) => {
              this.data = data.items_list
              this.pagData.maxItems = data.total_items
            })
            .catch(error => console.error(error))
      },
      onOrderClick(order) {
        this.$router.push(`/order/${order}`)
      },
      onFiltersApply(data) {
        let params = data
          billinkAxios
              .get(`app/cib-order-statuses`, {params})
              .then(({ data }) => {
                if (data.items_list.length) {
                  this.data = data.items_list
                  this.pagData.maxItems = data.total_items
                } else {
                  this.data = []
                  this.pagData.maxItems = 0
                  this.activeFilters = {}
                  // this.getCibStatuses()
                }
              })
              .catch(error => console.error(error))
      },
      onClearFilters() {
        this.activeFilters = {}
        this.getCibStatuses()
      },
      toggleFilter() {
        this.$refs.collectionFilter.toggleFilter()
      }

    },
    computed: {
      ...mapGetters({
        activeLanguage: 'getCollectionLanguage',
        paginationLanguage: 'getOrdersLanguage',
      }),
      formattedAmount() {
        return (amount) => Helper.formatAmount(amount)
      }
    },
    components: {
      CollectionFilter,
      Pagination,
      PaginationCount
    }
  }
</script>